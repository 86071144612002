<template>
    <div class="settingcuration_container">
        <confirm-yesno v-if="isConfirmModal" @cancel-click="onCancelClick" @confirm-click="onConfirmClick" />
        <confirm-yesno
            v-if="isCheckDupCuration"
            :contents="`설정하신 시간대에 세팅된 큐레이션이 있습니다.<br/>계속할까요?`"
            @cancel-click="onCancelDupCuraion"
            @confirm-click="onConfirmDupCuraion"
        />
        <confirm-yesno
            v-if="isCheckTimeDupCuration"
            :contents="`설정하신 시간대에 세팅된 큐레이션이 있습니다.<br/>계속할까요?`"
            @cancel-click="onCancelTimeDupCuration"
            @confirm-click="onConfirmTimeDupCuration"
        />
        <time-setting v-if="isSettingStartTime" :userInfo="{ startTime: startTime }" @insertPc="onInsertPcStart" />
        <time-setting v-if="isSettingEndTime" :userInfo="{ startTime: endTime }" @insertPc="onInsertEndTime" />
        <time-settingmobile
            v-if="isSettingStartTime"
            :userInfo="{ startTime: startTime }"
            @insertMobile="onInsertPcStart"
        ></time-settingmobile>
        <time-settingmobile
            v-if="isSettingEndTime"
            :userInfo="{ startTime: endTime }"
            @insertMobile="onInsertEndTime"
        ></time-settingmobile>
        <alert-modal v-if="isAlertModal" :alertText="alertText" @closeModal="onCloseAlertModal" />
        <component
            :is="activeComp"
            v-bind="{ ...getprops }"
            @clickday="onClickDay"
            @click-starttime="isSettingStartTime = true"
            @click-endtime="isSettingEndTime = true"
            @click-setcuration="onClickSetCuration"
            @save-curation="onSaveCuration"
            @close-curationinfo="onCloseCurationInfo"
            @delete-curation="isConfirmModal = true"
            @click-apply="onClickApplay"
            @click-closecurationtime="onClickCloseCurationTime"
        ></component>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import {
  createCurationInfoV2,
  updateCurationAllTemp,
  getMoodAndGenre,
  mySpaceInfo,
  getSpaceCurationInfo,
  deleteCurationInfo,
  selectCurationInfoAll,
  checkOverlappingTime,
  automaticRecommOfPreferMusic,
  updateConfirmCuration,
  getCurationTemp
} from '@/service/api/profileApi';
import { curationValidation, isGoFavoriteMusic, retryAutomaticRecomm } from '@/utils/Utils';
import { leaveChannel } from '@/assets/js/webcast';
export default defineComponent({
  name: 'SettingCurationView',
  components: {
    'confirm-yesno': () => import(`@/components/common/ConfirmYesNo.vue`),
    'time-setting': () => import(`@/components/modal/AutoPlay/StartPcAuto.vue`),
    'time-settingmobile': () => import(`@/components/modal/AutoPlay/StartMobileAuto.vue`),
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`),
    'setting-curationtime': () => import(`@/components/curation/SettingCurationTime.vue`),
    'setting-curationinfo': () => import(`@/components/curation/SettingCurationInfo.vue`)
  },
  data () {
    return {
      startTime: null,
      endTime: null,
      isSettingStartTime: false,
      isSettingEndTime: false,
      spaceId: '',
      selectDay: [],
      isAlertModal: false,
      alertText: '',
      activeComp: 'setting-curationtime',
      curationInfo: null,
      curationTag: {},
      spaceInfo: null,
      curationId: null,
      headers: {
        'X-AUTH-TOKEN': this.$cookies.get('aToken')
      },
      datatype: -1,
      isConfirmModal: false,
      isCheckDupCuration: false,
      isCheckTimeDupCuration: false,
      curationList: [],
      saveCurationData: null,
      isBtnDisabled: false,
      isCurationTimeBtnDisabled: false,
      dupCurationList: []
    };
  },

  async created () {
    const { spaceid, curationid } = this.$route.params;
    if (spaceid) {
      this.spaceId = spaceid;
      if (curationid) {
        this.curationId = curationid;
        if (this.$route.query.curationtype) {
          const { curationtype } = this.$route.query;
          if (curationtype === 'default') {
            //
            this.activeComp = 'setting-curationinfo';
          }
        }
      }
      this.setCurationList();
      this.setSpaceType(this.spaceId);
    }
  },

  mounted () {},

  methods: {
    async onClickApplay () {
      try {
        this.isCurationTimeBtnDisabled = true;
        if (this.selectDay && this.startTime && this.endTime) {
          const { data } = await checkOverlappingTime(
            this.spaceId,
            this.selectDay.join(','),
            this.startTime,
            this.endTime
          );

          const { resultCd, result } = data;
          if (resultCd === '0000') {
            const dupCuration = result.filter(item => item !== this.curationId);
            if (dupCuration && dupCuration.length > 0) {
              this.dupCurationList = dupCuration;
              this.isCheckTimeDupCuration = true;
              return false;
            } else {
              // 신규일 경우
              if (this.datatype !== 1) {
                this.saveCurationStore(this.curationInfo);
              } else {
                // 신규 아닌경우

                const saveTimeCurationResult = await this.saveTimeCuration();
                if (saveTimeCurationResult) {
                  await this.setCurationList();
                  // 현재일자/시간에 중첩되는지 확인
                  const find = this.isIncludeTime();
                  if (find) {
                    this.$VideoPlayer.onPause();
                    await leaveChannel();
                    this.$store.commit('setChannelId', '');
                    this.$store.commit('setChannelUrl', null);
                    this.$store.dispatch('player/createChannel', { retry: true });
                  }
                  this.$router.push({ path: `/curationsettingtime/${this.spaceId}` }).catch(() => {});
                }
              }
            }
          } else {
            return false;
          }
        }
      } catch (error) {
        //
        console.error('error : ', error.reponse);
      } finally {
        this.isCurationTimeBtnDisabled = false;
      }
    },
    async saveTimeCuration () {
      try {
        const { color, material } = this.curationInfo;
        // getMoodEmotion
        const resultMoodEmotion = await this.getMoodEmotion(color, material.sort((a, b) => a - b).join(','));
        // getTargetUnit
        const targetUnit = this.getTargetUnit(this.curationInfo.target);
        // tempCuration
        const sendCuration = {
          spaceId: this.spaceId,
          mood: resultMoodEmotion.mood,
          emotion: resultMoodEmotion.emotion,
          domesticRate: this.curationInfo.domesticRate,
          foreignRate: String(100 - parseInt(this.curationInfo.domesticRate)),
          genre: this.curationInfo.genre ? this.curationInfo.genre.sort((a, b) => a - b).join(',') : '',
          genreTuneN: this.curationInfo.genreTuneN ? this.curationInfo.genreTuneN.sort((a, b) => a - b).join(',') : '',
          genreTuneY: this.curationInfo.genreTuneY ? this.curationInfo.genreTuneY.sort((a, b) => a - b).join(',') : '',
          period: this.curationInfo.period.sort((a, b) => a - b).join(','),
          curationId: this.curationId,
          target: this.curationInfo.target.sort((a, b) => a - b).join(','),
          targetUnit,
          color,
          dayOfWeek: this.selectDay.join(','),
          startTime: this.startTime,
          endTime: this.endTime,
          material: material.sort((a, b) => a - b).join(',')
        };
        const tempResult = await this.saveCurationTemp(sendCuration);

        if (tempResult) {
          const { data: confirmCurationData } = await updateConfirmCuration(this.spaceId, this.curationId);
          const { resultCd: confirmResultCd } = confirmCurationData;
          if (confirmResultCd === '0000') {
            return true;
          } else {
            return false;
          }
        }
        return false;
      } catch (error) {
        console.log('successTimeSaveCuration error : ', error);
        return false;
      } finally {
        this.isCurationTimeBtnDisabled = false;
      }
    },
    getTargetUnit (target) {
      let targetUnit = '';
      if (target) {
        let array = [];
        target.forEach(val => {
          const item = this.$store.getters['player/getTargetUnitFilter'](val);
          array = [...array, ...item];
        });
        const set = new Set(array);
        targetUnit = Array.from(set).join(',');
      }
      return targetUnit;
    },
    onCancelDupCuraion () {
      this.isCheckDupCuration = false;
    },
    onConfirmDupCuraion () {
      this.saveCurationStore(this.saveCurationData);
      this.isCheckDupCuration = false;
      this.activeComp = 'setting-curationinfo';
    },
    onCancelTimeDupCuration () {
      this.isCheckTimeDupCuration = false;
    },
    async onConfirmTimeDupCuration () {
      this.isCheckTimeDupCuration = false;
      const len = this.dupCurationList.length;
      for (let dupIndex = 0; dupIndex < len; dupIndex++) {
        await deleteCurationInfo(this.dupCurationList[dupIndex]);
      }

      if (this.datatype !== 1) {
        this.saveCurationStore(this.curationInfo);
      } else {
        const saveTimeCurationResult = await this.saveTimeCuration();
        if (saveTimeCurationResult) {
          await this.setCurationList();
          // 현재일자/시간에 중첩되는지 확인
          const find = this.isIncludeTime();
          if (find) {
            this.$VideoPlayer.onPause();
            await leaveChannel();
            this.$store.commit('setChannelId', '');
            this.$store.commit('setChannelUrl', null);
            this.$store.dispatch('player/createChannel', { retry: true });
          }
          this.$router.push({ path: `/curationsettingtime/${this.spaceId}` }).catch(() => {});
        }
      }
    },
    async setCurationList () {
      await selectCurationInfoAll(this.spaceId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            this.curationList = result;
          } else {
            this.curationList = [];
          }
        })
        .catch(() => {
          this.curationList = [];
        });
    },
    isIncludeTime () {
      const now = new Date();
      const curTime = now.getHours() * 60 + now.getMinutes();
      const day = now.getDay();
      const find = this.curationList.find(item => {
        const dayOfWeek = item.dayOfWeek;
        if (item.startTime && item.endTime) {
          const start = item.startTime.split(':');
          const startHour = start[0];
          const startMin = start[1];

          const end = item.endTime.split(':');
          const endHour = end[0];
          const endtMin = end[1];

          const startTime = parseInt(startHour) * 60 + parseInt(startMin);
          const endTime = parseInt(endHour) * 60 + parseInt(endtMin);

          if (
            curTime >= startTime &&
            curTime <= endTime &&
            item.curationId === this.curationId &&
            dayOfWeek.includes(day)
          ) {
            if (this.curationId !== this.$store.getters['player/getCreatorCurationId']) {
              return true;
            }
            return false;
          } else {
            if (this.curationId === this.$store.getters['player/getCreatorCurationId']) {
              if (curTime < startTime || curTime > endTime) {
                return true;
              } else if (!dayOfWeek.includes(day)) {
                return true;
              }
            }
            return false;
          }
        }
      });
      return find;
    },
    onConfirmClick () {
      deleteCurationInfo(this.curationId)
        .then(async res => {
          const { resultCd } = res.data;
          await this.setCurationList();

          const find = this.isIncludeTime();

          if (resultCd === '0000') {
            if (find || this.curationId === this.$store.getters['player/getCreatorCurationId']) {
              this.$VideoPlayer.onPause();
              await leaveChannel();
              this.$store.commit('setChannelId', '');
              this.$store.commit('setChannelUrl', null);
              this.$store.dispatch('player/createChannel', { retry: true });
            }
            this.$router.push({ path: `/curationsettingtime/${this.spaceId}` }).catch(() => {});
          }
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
            const { data } = error.response;
            const { resultMsg } = data;
            this.isAlertModal = this;
            this.alertText = resultMsg;
          }
        })
        .finally(() => {
          this.isConfirmModal = false;
        });
    },
    onCancelClick () {
      this.isConfirmModal = false;
    },
    async setSpaceType (spaceId) {
      try {
        const { data } = await mySpaceInfo(spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          this.spaceInfo = result;
          const { spaceType } = result;

          if (spaceType === 'Car') {
            this.activeComp = 'setting-curationinfo';
            this.setCurationInfo();
          } else {
            const { type } = this.$route.query;
            if (!type) this.setCurationInfo();
            else {
              this.startTime = '09:00';
              this.endTime = '18:00';
            }
          }
        }
      } catch (error) {
        this.$router.push({ name: 'MySpace' });
      }
    },
    setCurationInfo () {
      getSpaceCurationInfo(this.spaceId, this.headers, this.curationId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            this.datatype = 1;
            this.settingCurationInfoData(result);
          }
        })
        .catch(err => {
          console.log('err : ', err);
          if (err.response) {
            const { status } = err.response;
            if (status === 400) {
              this.getCurationInfoTemp();
            } else {
              this.$router.push({ name: 'MySpace' }).catch(() => {});
            }
          }
        });
    },
    settingCurationInfoData (curation) {
      const result = curation;
      if (!result.genre) {
        result.genre = [];
      }

      if (result.genreTuneN) {
        result.genreTuneN = [...result.genreTuneN];
      }

      if (result.genreTuneY) {
        result.genreTuneY = [...result.genreTuneY];
      }
      this.curationTag = result;
      const curationObject = {};
      const {
        color,
        material,
        spaceType,
        carPurpose,
        target,
        domesticRate,
        genre,
        period,
        curationId,
        startTime,
        endTime,
        dayOfWeek,
        genreTuneN,
        genreTuneY
      } = result;
      this.curationId = curationId;
      // 국내곡 비율
      curationObject.domesticRate = domesticRate;
      // 장르
      if (genre) {
        const genreValueList = [];
        genre.forEach(item => {
          const findItem = this.$store.getters['curation/getGenreValue'](item);
          if (findItem) {
            genreValueList.push(parseInt(findItem.value));
          }
        });
        curationObject.genre = genreValueList;
      }

      if (genreTuneN) {
        const genreTuneNValueList = [];
        genreTuneN.forEach(item => {
          const findItem = this.$store.getters['curation/getGenreValue'](item);
          if (findItem) {
            genreTuneNValueList.push(parseInt(findItem.value));
          }
        });
        curationObject.genreTuneN = genreTuneNValueList;
      }

      if (genreTuneY) {
        const genreTuneYValueList = [];
        genreTuneY.forEach(item => {
          const findItem = this.$store.getters['curation/getGenreValue'](item);
          if (findItem) {
            genreTuneYValueList.push(parseInt(findItem.value));
          }
        });
        curationObject.genreTuneY = genreTuneYValueList;
      }

      // 시대
      if (period) {
        const peroidValueList = [];
        period.forEach(item => {
          const findItem = this.$store.getters['curation/getPeroidValue'](item);
          if (findItem) {
            peroidValueList.push(parseInt(findItem.value));
          }
        });
        curationObject.period = peroidValueList;
      }

      if (spaceType === 'Car') {
        // 차량 이용목적
        curationObject.carPurpose = this.$store.getters['curation/getCarPurPoseValue'](carPurpose[0])
          ? this.$store.getters['curation/getCarPurPoseValue'](carPurpose[0]).value
          : -1;
      } else {
        // 주요고객층
        if (target) {
          const targetlist = [];
          target.forEach(element => {
            const find = this.$store.getters['curation/getTargetValue'](element);
            if (find) {
              targetlist.push(parseInt(find.value));
            }
          });
          curationObject.target = targetlist;
        }

        // 색상
        if (color) {
          const findColor = this.$store.getters['curation/getColorItem'](color);
          if (findColor) {
            curationObject.color = findColor.no;
          } else {
            curationObject.color = -1;
          }
        }
        // 소재
        if (material) {
          const materialList = material.map(item => this.$store.getters['curation/getMaterialValue'](item).no);
          curationObject.material = materialList;
        }

        // 요일 컴퍼넌트 띄우기
        if (startTime && endTime) {
          this.startTime = startTime;
          this.endTime = endTime;
          const splitDayOfWeek = dayOfWeek
            .split(',')
            .map(item => parseInt(item))
            .sort((a, b) => a - b);
          this.selectDay = splitDayOfWeek;
        } else {
          // 큐레이션 수정 띄위기
          this.activeComp = 'setting-curationinfo';
        }
      }

      this.curationInfo = curationObject;
      this.$forceUpdate();
    },
    async getCurationInfoTemp () {
      try {
        const { data } = await getCurationTemp(this.curationId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          this.datatype = -1;
          this.settingCurationInfoData(result);
        }
      } catch (error) {
        this.$router.push({ name: 'MySpace' }).catch(() => {});
        console.error('Error : ', error);
      }
    },
    onCloseCurationInfo (val) {
      if (this.spaceInfo.spaceType === 'Store') {
        this.curationInfo = val;

        if (this.startTime && this.endTime) {
          this.activeComp = 'setting-curationtime';
          return true;
        }
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'MySpace' }).catch(() => {});
      }
    },
    onCloseAlertModal () {
      this.isAlertModal = false;
      this.alertText = '';
    },
    onInsertPcStart (val) {
      const { hour, minute } = val;
      this.isSettingStartTime = false;
      this.startTime = `${hour}:${minute}`;
    },
    onInsertEndTime (val) {
      const { hour, minute } = val;
      this.isSettingEndTime = false;
      this.endTime = `${hour}:${minute}`;
    },
    onClickDay (item) {
      const { value } = item;
      const findIdx = this.selectDay.findIndex(item => item === parseInt(value));
      if (findIdx > -1) {
        //
        this.selectDay.splice(findIdx, 1);
      } else {
        this.selectDay.push(parseInt(value));
      }
    },
    async getMoodEmotion (color, material) {
      try {
        const { data } = await getMoodAndGenre(color, material);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          const emotionlist = [];
          const { mood, emotion: returnEmotion } = result;
          returnEmotion.forEach(item => {
            const findItem = this.$store.getters['curation/getEmotionValue'](item);
            if (findItem) {
              emotionlist.push(parseInt(findItem.value));
            }
          });
          return {
            mood,
            emotion: emotionlist.sort((a, b) => a - b).join(',')
          };
        } else {
          return {
            mood: '',
            emotion: ''
          };
        }
      } catch (error) {
        //
        return {
          mood: '',
          emotion: ''
        };
      }
    },
    async saveCurationTemp (curationInfo) {
      try {
        const { data: curationTempData } = await updateCurationAllTemp(curationInfo);
        const { resultCd: curationTempResultCd } = curationTempData;
        if (curationTempResultCd === '0000') {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        //
        return false;
      }
    },
    async saveCurationStore (
      curationinfo,
      successFn = () => {
        this.$router.push({ path: `/space/insertStore5/${this.curationId}` }).catch(() => {});
      },
      successFnFavorite = () => {
        this.$router.push({ path: `/space/insertStoreFavorite/${this.spaceId}/${this.curationId}` }).catch(() => {});
      }
    ) {
      try {
        this.isBtnDisabled = true;
        if (this.datatype !== 1) {
          const { data } = await createCurationInfoV2(
            this.spaceId,
            this.selectDay.join(','),
            this.startTime,
            this.endTime
          );
          const { resultCd: curationResultCd, result: curationId } = data;
          if (curationResultCd !== '0000') return;
          this.curationId = curationId;
        }
        if (curationinfo) {
          const { color, material } = curationinfo;
          const resultMoodEmotion = await this.getMoodEmotion(color, material.sort((a, b) => a - b).join(','));
          let targetUnit = '';
          // targetUnit
          if (curationinfo.target) {
            let array = [];
            curationinfo.target.forEach(val => {
              const item = this.$store.getters['player/getTargetUnitFilter'](val);
              array = [...array, ...item];
            });
            const set = new Set(array);
            targetUnit = Array.from(set).join(',');
          }
          const { genre, genreTuneN, genreTuneY } = curationinfo;
          let genreConcat = [];
          if (genre) {
            genreConcat = [...genre];
          }

          if (genreTuneN) {
            genreConcat = [...genreConcat, ...genreTuneN.split(',')];
          }

          if (genreTuneY) {
            genreConcat = [...genreConcat, ...genreTuneY.split(',')];
          }
          const set = new Set(genreConcat);
          const isGoFavorite = isGoFavoriteMusic(Array.from(set).map(item => parseInt(item)));
          // 선호음악
          const { data: curationTempData } = await updateCurationAllTemp({
            spaceId: this.spaceId,
            mood: resultMoodEmotion.mood,
            emotion: resultMoodEmotion.emotion,
            domesticRate: curationinfo.domesticRate,
            foreignRate: String(100 - parseInt(curationinfo.domesticRate)),
            genre: curationinfo.genre.sort((a, b) => a - b).join(','),
            genreTuneN: curationinfo.genreTuneN,
            genreTuneY: curationinfo.genreTuneY,
            period: curationinfo.period.sort((a, b) => a - b).join(','),
            curationId: this.curationId,
            target: curationinfo.target.sort((a, b) => a - b).join(','),
            targetUnit,
            color,
            dayOfWeek: this.selectDay.join(','),
            startTime: this.startTime,
            endTime: this.endTime,
            material: material.sort((a, b) => a - b).join(',')
          });
          const { resultCd: curationTempResultCd } = curationTempData;
          if (curationTempResultCd === '0000') {
            if (isGoFavorite) {
              successFnFavorite();
            } else {
              await updateConfirmCuration(this.spaceId, this.curationId);
              const { data: recommPreferData } = await automaticRecommOfPreferMusic(this.curationId);
              const { resultCd: recommPreferResultCd } = recommPreferData;
              if (recommPreferResultCd !== '0000') return false;
              successFn();
            }
          }
        }
      } catch (error) {
        if (error.response) {
          const { resultCd, resultMsg } = error.response.data;
          const { url } = error.response.config;
          if (parseInt(resultCd) === 400) {
            this.isAlertModal = true;
            this.alertText = resultMsg;
          }
          if (url === '/api/profile/automaticRecommOfPreferMusic') {
            console.log(error.response);
            retryAutomaticRecomm(
              0,
              () => {
                successFn();
              },
              () => {
                this.isBtnDisabled = false;
              }
            );
          }
        }
        console.error('onSaveCuration : ', error);
      } finally {
        this.isBtnDisabled = false;
        this.isCurationTimeBtnDisabled = false;
      }
    },
    async onSaveCuration (curationinfo) {
      let genre = [];
      localStorage.setItem('spaceId', this.spaceId);
      if (curationinfo.genre.length > 0) {
        genre = [...curationinfo.genre];
      }

      if (curationinfo.genreTuneN) {
        const genreTuneNList = curationinfo.genreTuneN.split(',').map(item => parseInt(item));
        genre = [...genre, ...genreTuneNList];
      }

      if (curationinfo.genreTuneY) {
        const genreTuneYList = curationinfo.genreTuneY.split(',').map(item => parseInt(item));
        genre = [...genre, ...genreTuneYList];
      }
      const result = curationValidation({ domesticRate: curationinfo.domesticRate }, genre, curationinfo.period);
      const { isAlert, text } = result;
      if (isAlert) {
        this.isAlertModal = true;
        this.alertText = text;
        return false;
      }
      if (this.spaceInfo.spaceType === 'Store') {
        this.saveCurationData = curationinfo;

        this.saveCurationStore(this.saveCurationData);
      } else {
        const { genre, genreTuneN, genreTuneY } = curationinfo;
        let genreConcat = [];
        if (genre) {
          genreConcat = [...genre];
        }

        if (genreTuneN) {
          genreConcat = [...genreConcat, ...genreTuneN.split(',')];
        }

        if (genreTuneY) {
          genreConcat = [...genreConcat, ...genreTuneY.split(',')];
        }
        const set = new Set(genreConcat);
        const isGoFavorite = isGoFavoriteMusic(Array.from(set).map(item => parseInt(item)));
        // 차량 Temp
        const { carPurPose } = curationinfo;
        const carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(carPurPose));
        const emotion = this.$store.getters['player/getCarEmotionFilter'](parseInt(carPurPose));
        const { data: curationTempData } = await updateCurationAllTemp({
          spaceId: this.spaceId,
          carMood,
          carPurPose,
          genreTuneN: curationinfo.genreTuneN,
          genreTuneY: curationinfo.genreTuneY,
          domesticRate: curationinfo.domesticRate,
          emotion: emotion.sort((a, b) => a - b).join(','),
          foreignRate: String(100 - parseInt(curationinfo.domesticRate)),
          genre: curationinfo.genre.sort((a, b) => a - b).join(','),
          period: curationinfo.period.sort((a, b) => a - b).join(',')
        });
        const { resultCd: curationTempResultCd } = curationTempData;
        if (curationTempResultCd === '0000') {
          if (isGoFavorite) {
            this.$router
              .push({ path: `/space/insertStoreFavorite/${this.spaceId}/${this.curationId}` })
              .catch(() => {});
          } else {
            await updateConfirmCuration(this.spaceId, this.curationId);
            const { data: recommPreferData } = await automaticRecommOfPreferMusic(this.curationId);
            const { resultCd: recommPreferResultCd } = recommPreferData;
            if (recommPreferResultCd !== '0000') return false;
            this.$router.push({ path: `/space/insertStore5/${this.curationId}` }).catch(() => {});
          }
        }
      }
    },
    async onClickSetCuration () {
      if (this.selectDay.length > 0) {
        try {
          if (this.selectDay && this.startTime && this.endTime) {
            const { data } = await checkOverlappingTime(
              this.spaceId,
              this.selectDay.join(','),
              this.startTime,
              this.endTime
            );

            const { resultCd, result } = data;
            if (resultCd === '0000') {
              const dupCuration = result.filter(item => item !== this.curationId);
              if (dupCuration && dupCuration.length > 0) {
                this.isCheckDupCuration = true;
                return false;
              }
            } else {
              return false;
            }
          }

          this.activeComp = 'setting-curationinfo';
        } catch (error) {
          //
          console.error('error : ', error.reponse);
        }
      } else {
        this.alertText = `요일을 선택해주세요`;
        this.isAlertModal = true;
      }
    },
    onClickCloseCurationTime () {
      this.$router.push({ path: `/curationsettingtime/${this.spaceId}` });
    }
  },
  computed: {
    getprops () {
      const compName = this.activeComp;
      if (compName === 'setting-curationtime') {
        return {
          start: this.startTime,
          end: this.endTime,
          selectday: this.selectDay,
          datatype: this.datatype,
          curationtag: this.curationTag,
          isbtndisabled: this.isCurationTimeBtnDisabled,
          curationinfo: this.curationInfo
        };
      } else if (compName === 'setting-curationinfo') {
        return {
          datatype: this.datatype,
          curationinfo: this.curationInfo,
          spacetype: this.spaceInfo ? this.spaceInfo.spaceType : 'Store',
          isbtndiabled: this.isBtnDisabled
        };
      }

      return {};
    }
  }
});
</script>
<style scoped src="@/assets/css/curation/settingcurationview.css"></style>
